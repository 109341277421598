// src/App.js
import React, { useState, useEffect } from 'react';
import 'milligram/dist/milligram.min.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { auth, firestore } from './firebase'; // Adjust the path as needed
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import Root from './Root';
import Header from './Header';
import UserProfile from './UserProfile';
import FolderView from './FolderView';
import SignUp from './SignUp';
import Login from './Login';
import EditProfile from './components/EditProfile';
import AddPost from './AddPost';
import CreateFolder from "./components/CreateFolder";
import ProtectedRoute from './components/ProtectedRoute';
import ForgotPassword from './components/ForgotPassword';
import UserLinksPage from './components/UserLinksPage';
import UserFeaturedPage from './components/UserFeaturedPage';

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const containerClass = currentUser ? 'container main text-center' : 'container main text-center';

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Fetch additional user data from Firestore
        const userDocRef = doc(firestore, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setCurrentUser(userDoc.data());
        } else {
          // Handle case where user data doesn't exist in Firestore
          setCurrentUser({
            uid: user.uid,
            username: user.displayName || 'username',
            fullname: user.displayName || 'Full Name',
            profileImageUrl: user.profileImageUrl || '/tellos-logo.png',
          });
        }
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }



  return (
    <Router>
      <Header />
      <section className={containerClass}>
        <Routes>
          <Route
            path="/"
            element={currentUser ? <Navigate to={`/u/${currentUser.username}`}/> : <Root />}
          />
          <Route
            path="/signup"
            element={currentUser ? <Navigate to={`/u/${currentUser.username}`} /> : <SignUp />}
          />
          <Route
            path="/login"
            element={currentUser ? <Navigate to={`/u/${currentUser.username}`}/> : <Login />}
          />
          <Route
            path="/forgot-password"
            element={currentUser ? <Navigate to={`/u/${currentUser.username}`}/> : <ForgotPassword />}
          />
          <Route path="/u/:username" element={<UserProfile />} />
          <Route path="/u/:username/links" element={<UserLinksPage />} />
          <Route path="/u/:username/featured" element={<UserFeaturedPage />} />
          <Route path="/f/:folderId" element={<FolderView />} />
          <Route path="/edit-profile" element={
            <ProtectedRoute>
              <EditProfile />
            </ProtectedRoute>
          } />
          <Route path="/add-post" element={
            <ProtectedRoute>
              <AddPost />
            </ProtectedRoute>
          } />
          <Route path="/create-folder" element={
            <ProtectedRoute>
              <CreateFolder />
            </ProtectedRoute>
          } />
        </Routes>
      </section>
    </Router>
  );
}

export default App;
