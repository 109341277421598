import { useState } from 'react';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from '../firebase'; // Ensure this is the initialized auth instance from your Firebase configuration

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setMessage('');
        setError('');

        try {
            await sendPasswordResetEmail(auth, email);
            setMessage('Check your inbox for further instructions');
        } catch (error) {
            console.error(error);
            setError('Failed to reset password: ' + error.message); // Show more detailed error
        }
    };

    return (
        <div>
            <h2>Reset Password</h2>
            {error && <p>{error}</p>}
            {message && <p>{message}</p>}
            <form onSubmit={handleSubmit}>
                <input
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                    placeholder="Email address"
                />
                <button type="submit">Reset Password</button>
            </form>
        </div>
    );
}

export default ForgotPassword;
