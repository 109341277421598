import Grid from './components/Grid';
import UserFeed from './components/UserFeed';
import { useAuth } from './context/AuthContext';

export default function Root() {
  const { currentUser } = useAuth();
  return (
    <>
      {currentUser ? (
        <div>
          <div className="landing-text">
            <UserFeed />
          </div>
        </div>
      ) : (
        <div>
          <div className="landing-text">
            <h3 className="black">Share & discover links</h3>
            <h3 className="dark-purple">to everything you're</h3>
            <h3 className="dark-purple">learning and creating</h3>
            <h3 className="dark-purple">on the web</h3>
          </div>
          <a className="signup-btn" href="/signup">Sign Up</a>
          <h5 style={{ marginTop: '20px' }}><a href="/login">Or Login</a></h5>
        </div>
      )}
      {currentUser ? ( <div></div> ) : ( <div><Grid /></div> )}
    </>
  );
}