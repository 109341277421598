import { useEffect, useState } from 'react';
import { firestore } from '../firebase'; // Adjust the path as needed
import { collection, query, where, orderBy, getDocs, doc, getDoc } from 'firebase/firestore';

function useFollowedUsersFeed(currentUserId) {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true); // Set loading to true when starting fetch
      try {
        // Fetch followed users
        const followingRef = collection(firestore, 'following', currentUserId, 'user-following');
        const followingSnapshot = await getDocs(followingRef);
        const followedUserIds = followingSnapshot.docs.map(doc => doc.id);

        if (followedUserIds.length > 0) {
          // Fetch posts from followed users
          const postsPromises = followedUserIds.map(async (userId) => {
            const postsRef = collection(firestore, 'posts');
            const q = query(postsRef, where('ownerUid', '==', userId), orderBy('timestamp', 'desc'));
            const postsSnapshot = await getDocs(q);
            return postsSnapshot.docs.map(async postDoc => {
              const postData = postDoc.data();
              const userRef = doc(firestore, 'users', postData.ownerUid);
              const userSnapshot = await getDoc(userRef);
              const userData = userSnapshot.data();
              return {
                id: postDoc.id,
                ...postData,
                userName: userData?.username || 'Unknown User',
                userAvatarUrl: userData?.profileImageUrl || 'https://picsum.photos/50',
              };
            });
          });

          // Resolve all post promises
          const postsArrays = await Promise.all(postsPromises);
          const allPosts = (await Promise.all(postsArrays.flat())).sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);
          setPosts(allPosts);
        }
      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        setLoading(false); // Set loading to false once fetching is complete
      }
    };

    fetchPosts();
  }, [currentUserId]);

  return { posts, loading }; // Return loading state along with posts
}

export default useFollowedUsersFeed;
