import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import './Post.css'; // Ensure you create a corresponding CSS file for styles
import RepostButton from './RepostButton';

function Post({ post }) {
  const defaultAvatarUrl = "https://example.com/default-avatar.jpg";

  // Convert Firestore timestamp to JavaScript Date object
  const postDate = post.timestamp ? post.timestamp.toDate() : new Date();
  const timeAgo = formatDistanceToNow(postDate, { addSuffix: true });

  const handleRepost = () => {
    console.log('Repost button clicked!');
    // Add your repost logic here
  };

  return (
    <div className="post">
      <div className="post-header">
        <img 
          src={post.userAvatarUrl ? post.userAvatarUrl : defaultAvatarUrl} 
          alt={post.userName} 
          className="post-avatar"
        />
        <div className="post-user-info">
          <h4><a href={"/u/" + post.userName}>{post.userName}</a> ({timeAgo})</h4>
        </div>
      </div>
      <div className="post-body">
        <h3 className="post-title">{post.title}</h3>
        <img 
          src={post.imageUrl}
          alt="Post content"
          className="post-image"
        />
        <a href={post.url} className="post-link">
          {post.url}
        </a>
        <p className="post-description">{post.caption}</p>
      </div>
      <div className="post-footer">
        <RepostButton onClick={handleRepost} />
      </div>
    </div>
  );
}

export default Post;
