import React from 'react';
import useFollowedUsersFeed from './useFollowedUsersFeed'; // Adjust the path as needed
import './Feed.css';
import Post from './Post';

function Feed({ currentUserId }) {
  const { posts, loading } = useFollowedUsersFeed(currentUserId);
  const defaultAvatarUrl = "https://picsum.photos/50";

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="feed">
      {posts.length === 0 ? (
        <p>No posts available.</p>
      ) : (
        posts.map(post => (
          <Post key={post.id} post={post} />
        ))
      )}
    </div>
  );
}

export default Feed;
