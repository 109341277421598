import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import * as axios from 'axios';

const storage = getStorage();

export const uploadImageToFirebase = async (imageUrl) => {
  try {
    const safeUrl = encodeURI(imageUrl);

    if (!safeUrl || safeUrl === "undefined") {
        console.error('Invalid or undefined image URL:', safeUrl);
        throw new Error('Invalid or undefined image URL');
    }

    console.log('Fetching image from URL:', safeUrl);
    const response = await axios.get(safeUrl, { responseType: 'arraybuffer' });

    console.log('Converting image to Blob');
    const blob = new Blob([response.data], { type: response.headers['content-type'] });

    const fileName = new Date().getTime() + '_' + safeUrl.split('/').pop().split('?')[0];
    const storageRef = ref(storage, `images/${fileName}`);

    console.log('Uploading image to Firebase Storage');
    await uploadBytes(storageRef, blob);

    const downloadUrl = await getDownloadURL(storageRef);
    console.log('Firebase Storage download URL:', downloadUrl);
    return downloadUrl;
  } catch (error) {
    console.error('Error uploading image to Firebase:', error);
    throw new Error('Failed to upload image due to:', error.response ? error.response.data : error.message);
  }
};
