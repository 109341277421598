import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons'; // "faRedo" is a commonly used icon for "repost"

// Style for the button
const buttonStyle = {
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  padding: '5px',
  fontSize: '20px', // Adjust size as needed
  color: '#9b4dca',
};

// Optional: Style for icon hover effect
const iconHoverStyle = {
  color: '#9b4dca', // Change color on hover if needed
};

const RepostButton = ({ onClick }) => {
  return (
    <button 
      style={buttonStyle} 
      onClick={onClick}
      title="Repost"
    >
      <FontAwesomeIcon 
        icon={faRedo} 
        style={iconHoverStyle} // Apply hover style if needed
      />
    </button>
  );
};

export default RepostButton;
