import React, { useEffect, useState } from 'react';
import { db, collection, query, getDocs } from './firebase';  // Adjust path as needed
import { where, doc, updateDoc, deleteDoc } from "firebase/firestore";  
import { useAuth } from './context/AuthContext';
import {
  MDBRow,
  MDBCol,
  MDBCardImage,
  MDBCardTitle,
  MDBTypography,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon
} from 'mdb-react-ui-kit';

const FetchFolderPosts = ({ folderId }) => {
  const { currentUser } = useAuth();
  const [data, setData] = useState([]);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(collection(db, 'folders/' + folderId + '/posts'));
        const querySnapshot = await getDocs(q);

        const items = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setData(items);

        // Extract postIds from the items
        const postIds = items.map(item => item.postId);

        if (postIds.length > 0) {
          // Fetch the posts that match the postIds
          const postsQuery = query(
            collection(db, 'posts'),
            where('__name__', 'in', postIds)
          );
          const postsSnapshot = await getDocs(postsQuery);

          const postsData = postsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          setPosts(postsData);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [folderId]);

  const handleFeatureToggle = async (postId, isFeatured) => {
    try {
      const postRef = doc(db, 'posts', postId);
      await updateDoc(postRef, { featured: !isFeatured });
      window.location.reload(); // Refresh the page after toggling feature status
    } catch (error) {
      console.error('Error toggling feature status:', error);
    }
  };

  const handleDeletePost = async (postId, folderId) => {
    try {
      // Delete from the main posts collection
      const postRef = doc(db, 'posts', postId);
      await deleteDoc(postRef);

      // Delete from the relevant folder's posts subcollection
      const folderPostsRef = collection(db, 'folders', folderId, 'posts');
      const q = query(folderPostsRef, where('postId', '==', postId));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref); // Delete the matching document from the folder's posts subcollection
      });

      // Optionally reload the page after deletion
      window.location.reload();
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message} {data}</div>;

  return (
    <MDBRow style={{ marginTop: "20px" }}>
      <MDBTypography tag="h4" className="font-weight-bold">Folder</MDBTypography>
      <div className="d-flex flex-wrap justify-content-center">
        {posts.map((post) => (
          <MDBCol key={post.id} md="3" sm="4" className="col-4 mb-4">
            <MDBCard>
              <a href={post.url || '#'} target="_blank" rel="noreferrer">
                <MDBCardImage
                  src={post.imageUrl || '/tellos-logo.png'}
                  alt={post.title}
                  className="card-header-image"
                />
              </a>
              <div className="d-flex justify-content-between align-items-start">
                <div className="text-container col-10">
                  <p className="post-card-title">{post.title}</p>
                  <p className="post-card-caption">
                    {post.caption || 'No description available.'}
                  </p>
                </div>

                {/* Show dropdown only if the post belongs to the current user */}
                {currentUser && currentUser.uid === post.ownerUid && (
                  <MDBDropdown className="col-2">
                    <MDBDropdownToggle tag="a" className="nav-link p-0">
                      <MDBIcon fas icon="ellipsis-v" />
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem link onClick={() => handleFeatureToggle(post.id, post.featured)}>
                        {post.featured ? 'Unfeature' : 'Feature'}
                      </MDBDropdownItem>
                      <MDBDropdownItem link onClick={() => handleDeletePost(post.id, post.folderId)}>
                        Delete
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                )}
              </div>
            </MDBCard>
          </MDBCol>
        ))}
      </div>
    </MDBRow>
  );
};

export default FetchFolderPosts;
