import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import axios from 'axios';

const storage = getStorage();

export const uploadFileDirectly = async (file) => {
    try {
        const storageRef = ref(storage, `images/${Date.now()}_${file.name}`);
        console.log('Uploading file directly to Firebase Storage');
        await uploadBytes(storageRef, file);

        const downloadUrl = await getDownloadURL(storageRef);
        console.log('Firebase Storage download URL:', downloadUrl);
        return downloadUrl;
    } catch (error) {
        console.error('Error uploading file directly to Firebase:', error);
        throw error; // Rethrow to handle in calling function
    }
};