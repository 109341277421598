import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { getFirestore, collection, addDoc, query, where, getDocs, serverTimestamp } from 'firebase/firestore';
import { uploadImageToFirebase } from '../utils/firebaseStorageUtils';
import { uploadFileDirectly } from '../utils/uploadFileDirectly';
import { useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';

const PostForm = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [description, setDescription] = useState('');
  const [caption, setCaption] = useState('');
  const [loading, setLoading] = useState(false);
  const [folders, setFolders] = useState([]);
  const [featured, setFeatured] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState('');
  const [isCreatingNewFolder, setIsCreatingNewFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [newFolderIsPrivate, setNewFolderIsPrivate] = useState(false);
  const [newFolderImage, setNewFolderImage] = useState(null);
  const [metadataError, setMetadataError] = useState(false);

  const db = getFirestore();

  useEffect(() => {
    const fetchFolders = async () => {
      if (currentUser) {
        const q = query(collection(db, 'folders'), where('ownerId', '==', currentUser.uid));
        const querySnapshot = await getDocs(q);
        const foldersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setFolders(foldersList);
      }
    };
    fetchFolders();
  }, [currentUser, db]);

  const fetchMetadata = async (url) => {
    try {
      if (!url) {
        throw new Error('URL is empty or undefined');
      }

      // Call the Cloud Function to fetch metadata
      const response = await fetch('https://us-central1-tellos-2ba21.cloudfunctions.net/extractMetadata', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ url })
      });

      if (!response.ok) {
        throw new Error(`Error fetching metadata: ${response.statusText}`);
      }

      const result = await response.json();
      const { title, imageUrl, description } = result;

      setTitle(title || '');
      setImageUrl(imageUrl || '');  // This URL now points to the uploaded image in Firebase Storage
      setDescription(description || '');

      return { title, imageUrl, description };
    } catch (error) {
      console.error('Error fetching metadata:', error.message);
      setMetadataError(true);
      return { title: '', imageUrl: '', description: '' };
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const metadata = await fetchMetadata(url);  // Fetch metadata, including uploaded image URL
      let folderId = selectedFolderId;

      if (isCreatingNewFolder) {
        let folderImageUrl = '';
        if (newFolderImage) {
          try {
            folderImageUrl = await uploadFileDirectly(newFolderImage);
          } catch (error) {
            console.error('Error uploading folder image:', error.message);
            if (error.message.includes('CORS')) {
              folderImageUrl = 'https://tellos.co/tellos-logo.png'; // Default folder image URL
            } else {
              throw error;
            }
          }
        }

        const newFolder = {
          name: newFolderName,
          isPrivateFolder: newFolderIsPrivate,
          ownerId: currentUser.uid,
          createAt: serverTimestamp(),
          imageUrl: folderImageUrl,
        };

        const folderRef = await addDoc(collection(db, 'folders'), newFolder);
        folderId = folderRef.id;
      }

      const privateUsersBy = newFolderIsPrivate ? [currentUser.uid] : [];
      const publicUsersBy = newFolderIsPrivate ? [] : [currentUser.uid];

      const postData = {
        url,
        title: metadata.title || title,
        imageUrl: metadata.imageUrl || 'https://tellos.co/tellos-logo.png',  // Use image URL from metadata
        description: metadata.description || description,
        caption: caption || '',
        timestamp: serverTimestamp(),
        ownerUid: currentUser.uid,
        folderId,
        featured: false,
        isFeatured: false,
        isSelect: false,
        likes: 0,
        privateUsersBy: newFolderIsPrivate ? [currentUser.uid] : [],
        publicUsersBy: newFolderIsPrivate ? [] : [currentUser.uid],
        replyCount: 0,
      };

      const postRef = await addDoc(collection(db, 'posts'), postData);

      // Add post to the folder's subcollection
      await addDoc(collection(db, `folders/${folderId}/posts`), {
        postId: postRef.id,
        userId: currentUser.uid,
      });

      // Add activity entry
      await addDoc(collection(db, `activity/${currentUser.uid}/user-notifications`), {
        senderUid: currentUser.uid,
        timestamp: serverTimestamp(),
        type: 2,
      });

      // Reset form fields
      setUrl('');
      setTitle('');
      setImageUrl('');
      setDescription('');
      setCaption('');
      setSelectedFolderId('');
      setIsCreatingNewFolder(false);
      setNewFolderName('');
      setNewFolderIsPrivate(false);
      setNewFolderImage(null);
      setMetadataError(false); // Reset error flag after submission
      navigate('/');
    } catch (error) {
      console.error('Error adding document:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFolderOptionChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === 'new') {
      setIsCreatingNewFolder(true);
      setSelectedFolderId(''); // Clear selected folder ID when creating a new folder
    } else {
      setIsCreatingNewFolder(false);
      setSelectedFolderId(selectedValue);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>URL:</label>
        <input
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          required
        />
      </div>

      {metadataError && (
        <>
          <div>
            <label>Title:</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter title"
              required
            />
          </div>

          <div>
            <label>Description:</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter description"
              required
            />
          </div>

          <div>
            <label>Image URL:</label>
            <input
              type="text"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              placeholder="Enter image URL"
              required
            />
          </div>
        </>
      )}

      <div>
        <label>Caption:</label>
        <textarea
          value={caption}
          onChange={(e) => setCaption(e.target.value)}
          placeholder="Add a caption"
        />
      </div>

      <div>
        <label>Folder:</label>
        <select value={selectedFolderId} onChange={handleFolderOptionChange}>
          <option value="">Select Folder</option>
          {folders.map(folder => (
            <option key={folder.id} value={folder.id}>{folder.name}</option>
          ))}
          <option value="new">Create New Folder</option>
        </select>
      </div>

      {isCreatingNewFolder && (
        <div>
          <label>New Folder Name:</label>
          <input
            type="text"
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            required
          />
          <label>Private Folder:</label>
          <input
            type="checkbox"
            checked={newFolderIsPrivate}
            onChange={(e) => setNewFolderIsPrivate(e.target.checked)}
          />
          <label>Folder Image:</label>
          <input
            type="file"
            onChange={(e) => setNewFolderImage(e.target.files[0])}
          />
        </div>
      )}

      <button type="submit" disabled={loading}>
        {loading ? 'Loading...' : 'Create Post'}
      </button>
    </form>
  );
};

export default PostForm;
