import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { db, storage } from '../firebase'; // Ensure you import storage
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const CreateFolder = () => {
  const { currentUser } = useAuth(); // Get current user
  const [name, setName] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [isPrivateFolder, setIsPrivateFolder] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCreateFolder = async () => {
    if (!name || !imageFile) {
      alert('Folder name and image are required');
      return;
    }

    setLoading(true);
    
    try {
      // Upload image to Firebase Storage
      const imageRef = ref(storage, `folders/${currentUser.uid}/${imageFile.name}`);
      await uploadBytes(imageRef, imageFile);
      const imageUrl = await getDownloadURL(imageRef);

      // Add folder details to Firestore
      const folderRef = await addDoc(collection(db, 'folders'), {
        name,
        imageUrl,
        isPrivateFolder,
        ownerId: currentUser.uid,
        createAt: serverTimestamp(),
      });

      console.log('Folder created with ID: ', folderRef.id);

      // Optionally clear the form
      setName('');
      setImageFile(null);
      setIsPrivateFolder(false);
    } catch (error) {
      console.error('Error creating folder: ', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Create a New Folder</h2>
      <form onSubmit={(e) => e.preventDefault()}>
        <div>
          <label>Folder Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImageFile(e.target.files[0])}
            required
          />
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              checked={isPrivateFolder}
              onChange={(e) => setIsPrivateFolder(e.target.checked)}
            />
            Private Folder
          </label>
        </div>
        <button type="button" onClick={handleCreateFolder} disabled={loading}>
          {loading ? 'Creating...' : 'Create Folder'}
        </button>
      </form>
    </div>
  );
};

export default CreateFolder;
