import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs, orderBy, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { MDBRow, MDBCol, MDBTypography, MDBCard, MDBCardImage, MDBCardBody, MDBCardTitle, MDBCardText, MDBContainer, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon } from 'mdb-react-ui-kit';
import BackArrow from './BackArrow';
import { useAuth } from '../context/AuthContext';

const UserFeaturedPage = () => {
  const { username } = useParams();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  const db = getFirestore();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        // Step 1: Fetch user details using username to get userID
        const usersRef = collection(db, 'users');
        const userQuery = query(usersRef, where('username', '==', username));
        const userSnapshot = await getDocs(userQuery);

        if (userSnapshot.empty) {
          console.error('No user found with the provided username');
          setLoading(false);
          return;
        }

        // Assume first document is the correct user
        const user = userSnapshot.docs[0].data();
        const userID = userSnapshot.docs[0].id;

        // Step 2: Fetch posts by userID
        const postsRef = collection(db, 'posts');
        const postsQuery = query(postsRef, where('ownerUid', '==', userID), where('featured', '==', true), orderBy('timestamp', 'desc'));
        const postsSnapshot = await getDocs(postsQuery);

        const fetchedPosts = postsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        setPosts(fetchedPosts);
      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [db, username]);

  const handleFeatureToggle = async (postId, isFeatured) => {
    try {
      const postRef = doc(db, 'posts', postId);
      await updateDoc(postRef, { featured: !isFeatured });
      setPosts(prevPosts =>
        prevPosts.map(post =>
          post.id === postId ? { ...post, featured: !isFeatured } : post
        )
      );
      window.location.reload();
    } catch (error) {
      console.error('Error toggling feature status:', error);
    }
  };

  const handleDeletePost = async (postId) => {
    try {
      const postRef = doc(db, 'posts', postId);
      await deleteDoc(postRef);
      setPosts(prevPosts => prevPosts.filter(post => post.id !== postId));
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const ensureAbsoluteUrl = (url) => {
    if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
      return `http://${url}`;
    }
    return url;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <MDBContainer>
      <BackArrow />
      <MDBTypography tag="h3" className="font-weight-bold text-center my-4">
        Featured Links by {username}
      </MDBTypography>
      <MDBRow className="d-flex flex-wrap justify-content-center">
        {posts.length === 0 ? (
          <MDBTypography tag="p" className="text-center">No links available.</MDBTypography>
        ) : (
          posts.map(post => (
            <MDBCol key={post.id} xs="12" sm="6" md="4" lg="3" className="mb-4">
              <MDBCard>
                <a href={ensureAbsoluteUrl(post.url) || '#'} target="_blank" rel="noreferrer">
                  <MDBCardImage
                    src={post.imageUrl || '/tellos-logo.png'}
                    alt={post.title}
                    className="card-header-image"
                  />
                </a>
                <MDBCardBody className="position-relative">
                  <MDBCardTitle className="post-card-title">{post.title}</MDBCardTitle>
                  <MDBCardText className="post-card-caption">{post.caption || 'No description available.'}</MDBCardText>

                  {/* 3-dot dropdown menu */}
                  {currentUser && currentUser.uid === post.ownerUid && (
                    <MDBDropdown className="position-absolute" style={{ top: '10px', right: '10px' }}>
                      <MDBDropdownToggle tag="a" className="nav-link">
                        <MDBIcon fas icon="ellipsis-v" />
                      </MDBDropdownToggle>
                      <MDBDropdownMenu>
                        <MDBDropdownItem link onClick={() => handleFeatureToggle(post.id, post.featured)}>
                          {post.featured ? 'Unfeature' : 'Feature'}
                        </MDBDropdownItem>
                        <MDBDropdownItem link onClick={() => handleDeletePost(post.id)}>
                          Delete
                        </MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  )}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          ))
        )}
      </MDBRow>
    </MDBContainer>
  );
};

export default UserFeaturedPage;
