import React from 'react';
import PostForm from './components/PostForm'; // Adjust the path if necessary
import './AddPost.css'; // Import the CSS file
import BackArrow from './components/BackArrow';

const AddPost = () => {
  return (
    <div className="add-post">
      <BackArrow />
      <h1>Add a New Link</h1>
      <PostForm />
    </div>
  );
};

export default AddPost;
