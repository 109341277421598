// src/FolderView.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import FetchFolderPosts from './FetchFolderPosts';
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardBody, MDBCardImage, MDBTypography } from 'mdb-react-ui-kit';

function FolderView() {
  const { folderId } = useParams();
  const [folder, setFolder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFolder = async () => {
      try {
        const folderRef = doc(db, 'folders', folderId);
        const folderDoc = await getDoc(folderRef);

        if (folderDoc.exists()) {
          setFolder(folderDoc.data());
        } else {
          console.log('No such folder with ID:', folderId);
        }
      } catch (error) {
        console.error('Error fetching folder:', error);
        setError('Error fetching folder data');
      } finally {
        setLoading(false);
      }
    };

    fetchFolder();
  }, [folderId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  

  return (
    <div>
      <MDBContainer className="py-5 h-100">
        <MDBRow className="justify-content-center align-items-center h-100">
          <MDBCol lg="12" xl="12">
            <MDBCard>
              <div className="rounded-top d-flex flex-row" style={{ borderBottom: '1px solid black', height: '200px' }}>
                <div className="ms-4 mt-5 d-flex flex-column" style={{ width: '150px' }}>
                  <MDBCardImage src={folder.imageUrl || '/tellos-logo.png'}
                    alt="Generic placeholder image" className="mt-4 mb-2 img-thumbnail" fluid style={{ width: '150px', height: '150px', zIndex: '1' }} />
                </div>
                <div className="ms-3" style={{ marginTop: '130px' }}>
                  <MDBTypography tag="h5">{folder.name}</MDBTypography>
                </div>
              </div>
              <MDBCardBody className="text-black p-4">
                <FetchFolderPosts folderId={folderId} />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}

export default FolderView;