import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase'; // Adjust the path as needed
import Feed from './Feed'; // Adjust the path as needed

function UserFeed() {
  const [currentUserId, setCurrentUserId] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUserId(user.uid);
      } else {
        setCurrentUserId(null);
      }
    });

    return () => unsubscribe();
  }, []);

  if (!currentUserId) {
    return <p>Loading user profile...</p>;
  }

  return <Feed currentUserId={currentUserId} />;
}

export default UserFeed;
