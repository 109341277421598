import React from 'react';
import './Header.css';
import { useAuth } from './context/AuthContext';
import { auth } from './firebase';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const { currentUser } = useAuth();
  const containerClass = currentUser ? 'logged-in' : '';
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate('/');  // Redirect to homepage after sign out
    } catch (error) {
      console.error('Sign out failed:', error);
    }
  };
  return (
    <header id='header' className={containerClass}>
      <span className='nav-heading nav-brand'><a href="/"><img alt="tellos logo" src="/tellos-logo.png" />tellos</a></span>
      {currentUser ? ( <div><input type='checkbox' className='menu-toggle' id='menu-toggle' />
      <label htmlFor='menu-toggle' className='menu-nav-toggle'>
        <span></span>
      </label>
      <nav className='header'>
        <ul>
            
              <li>
                <p>{currentUser.email}</p>
              </li>
              <li>
                <a href='/edit-profile'>Edit Profile</a>
              </li>
              <li>
                <a href='/add-post'>Add A Link</a>
              </li>
              <li>
                <button onClick={handleSignOut}>Sign Out</button>
              </li>
              </ul>
              </nav>
            </div>
          ) : (
            <div>
              <a className="signup-btn" href="/signup">Sign Up</a>
            </div>
          )}
        
      
    </header>
  );
};

export default Header;
