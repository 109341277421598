// src/components/BackArrow.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BackArrow.css'; // Import CSS for styling

const BackArrow = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigates to the previous page in the browser history
  };

  return (
    <div className="back-arrow" onClick={handleBack}>
      {/* SVG Arrow Icon */}
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 18L9 12L15 6"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default BackArrow;
