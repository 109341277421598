import React from 'react';
import './Grid.css'; // Import your custom CSS file
//import useRandomImageUrl from '../components/useRandomImageUrl'

const Grid = () => {
  const images = [
    'https://picsum.photos/250/500',
    'https://picsum.photos/250/400?grayscale',
    'https://picsum.photos/250/336',
    'https://picsum.photos/250/400',
    'https://picsum.photos/250/500?grayscale',
  ];
  /*const images = [
    useRandomImageUrl(),
    useRandomImageUrl(),
    useRandomImageUrl(),
    useRandomImageUrl(),
    useRandomImageUrl(),
  ];*/

  return (
    <div className="grid-container">
      {images.map((src, index) => (
        <div key={index} className="grid-item">
          <img src={src} alt={`${index}`} />
        </div>
      ))}
    </div>
  );
};

export default Grid;
