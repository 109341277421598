import React from 'react';

const ActionButton = ({ user, username }) => {
  // Check if user and username are defined
  if (!user || !username) {
    return <div>Loading...</div>;
  }

  // Conditionally render the button based on authentication and username
  let actionButton = null;
  let displayStyle = "none";

  if (user) {
    console.log(user.username);
    console.log(username);
    // Assuming currentUser has a `username` property
    if (user.username === username) {
      actionButton = "Edit Profile";
      displayStyle = "block";
    } else {
      //actionButton = "Follow";
    }
  }

  return (
    <div style={{ marginTop: "10px", display: displayStyle }}>
      <a href="/edit-profile">
        {actionButton && <button>{actionButton}</button>}
      </a>
    </div>
  );
};

export default ActionButton;
