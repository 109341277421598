import React, { useEffect, useState } from 'react';
import { db, collection, query, where, getDocs } from './firebase';  // Adjust path as needed
import { MDBCardText } from 'mdb-react-ui-kit';

const FetchUserCollection = ({ userId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(collection(db, 'posts'), where('ownerUid', '==', userId));
        const querySnapshot = await getDocs(q);

        const items = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setData(items);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [userId]);

  if (loading) return <b>Loading...</b>;
  if (error) return <b>Error: {error.message}</b>;
  
  // Safely handle empty data
  return (
    <MDBCardText className="mb-1 h5">
      {data.length > 0 ? data.length : '0'} Links
    </MDBCardText>
  );
};

export default FetchUserCollection;
